import React from 'react'
import {Link} from 'gatsby'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import HelpIcon from '@mui/icons-material/Help'
import Box from '@mui/material/Box'
import styled from "styled-components"


const Header = () =>
    <Wrapper>
        <Box sx={{flexGrow: 1}}>
            <AppBar position='static' style={{backgroundColor: 'white', color: '#000'}}>
                <Toolbar style={{minHeight: '64px'}}>
                        <IconButton size='large' edge='start' color='inherit' aria-label='menu' sx={{mr: 2}}>
                            <AttachMoneyIcon/>
                        </IconButton>
                        <Typo>
                            {/*<Link to='/'>CalcLot - lot size calculator - </Link>*/}
                            <Link to='/'>コイン図鑑</Link>
                        </Typo>
                    {/*<Button color='inherit'>Help</Button>*/}
                    <IconButton size="large" aria-label="display more actions" edge="end" color="inherit">
                        {/*<Link to='/about'><HelpIcon/></Link>*/}
                        <HelpIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    </Wrapper>


export default Header


const Wrapper = styled.section`
    font-family: "M PLUS 1p";
    a {
        color: #000;
        text-decoration: none;
    }
`

const Typo = styled.div`
    margin: 0;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
`