import * as React from 'react'
import PropTypes from "prop-types"
import {useStaticQuery, graphql, Link} from 'gatsby'
import '@fontsource/m-plus-1p'

import Header from './header'
import './layout.css'
import styled, {css} from 'styled-components'

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
            <Wrapper>
                <main>{children}</main>
            </Wrapper>
            <Footer>
                <span>© {new Date().getFullYear()}, コイン図鑑 {` `}</span>
                <span><Link to='/about'>About</Link></span>
                <span><Link to='/privacy'>プライバシポリシー</Link></span>
            </Footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout


export const media = {
    small: (...args) => css`
        @media (max-width: 450px) {
            ${css(...args)};
        }
    `,
    medium : (...args) => css`
        @media (min-width: 450px) and (max-width: 768px) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: 768px) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: 1200px) {
            ${css(...args)};
        }
    `,
    lessThanLarge : (...args) => css`
        @media (max-width: 768px) {
            ${css(...args)};
        }
    `,
    greaterThanSmall : (...args) => css`
        @media (min-width: 450px) {
            ${css(...args)};
        }
    `,
}


const Wrapper = styled.div`
    min-height: 100vh;
    
    main {
        // display: grid;
        // justify-content: center;
    }
`

const Footer = styled.footer`
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px; 
    width: calc(( 800 / 1280 ) *100vw );
    margin: 20px auto 0 auto;
    text-align: center;
    
    ${media.small`
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        row-gap: 10px;
        padding: 10px;
    `} 
    
    ${media.large`
        padding: 10px calc(( 100 / 1280 ) *100vw );
    `} 
`